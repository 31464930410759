import { createStore } from 'vuex'
import custmr from './modules/custmr'
import hyzadm from './modules/hyzadm'

export default createStore({
  modules: {
    custmr,
    hyzadm
  }
})
