
function currentUser() {
    const cuser = JSON.parse(localStorage.getItem('nrsllrcust'));
    return cuser
}


export const uzr = {
    currentUser
};
