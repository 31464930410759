import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/genesis'
import VueFeather from 'vue-feather';
import emailormobile from './validation/mobileoremail'
import age18Plus from './validation/adultCheck'
import VueLazyload from 'vue-lazyload'
import mitt from 'mitt';  

// import { requestNotificationPermission } from './services/firebase';


const emitter = mitt();  
// requestNotificationPermission();
// export {requestNotificationPermission}
const app = createApp(App)
app.provide('emitter', emitter); 

app.component('VueFeather', VueFeather)
app.use(store)
    .use(VueGoodTablePlugin)
    .use(router)
    .use(plugin, defaultConfig({
        rules: { emailormobile,
             age_18_plus: age18Plus, },
    }))
    .use(VueLazyload)
    // .use(requestNotificationPermission)
    .mount('#app')